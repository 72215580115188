//
// Error 5
//

// Initialization of global variables, mixins and functions
@import "../../init";

.archive-input {
    font-family: Poppins, Helvetica, "sans-serif" !important;
    background-color: #ffffff !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.archive-search {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0px;
    margin-bottom: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	
	.sidebarfilters.right > div {
		padding: get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Container
	.sidebarfilters.right > div {
		max-width: none;
		padding: get($page-padding, tablet-and-mobile);
	}
}