//
// Privacy
// Pages SASS files are compiled into separate css files
//


// Initialization of global variables, mixins and functions
@import "../../init";

// Desktop Mode
@include media-breakpoint-up(lg) {
	

	// Container no-padding
	.container .no-padding,
	.container-fluid .no-padding {
		margin: 0 - get($page-padding, desktop);
	}
}


// Tablet & Mobile Modes
@include media-breakpoint-down(md) {

	// Container
	.container .no-padding,
	.container-fluid .no-padding {
		margin: 0 - get($page-padding, desktop);
	}
}
